// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { AuthConfig } from "angular-oauth2-oidc";


export const environment = {
  production: false,
  R3_GI: 'https://qascbmpi.scg.com:44332/RESTAdapter/R3GIGRRefDN',
  S4_GI: 'https://qascbmpi.scg.com:44332/RESTAdapter/GIGRRefDN',
  R3_GI_Reverse: 'https://qascbmpi.scg.com:44332/RESTAdapter/R3REVGIGRRefDN',
  S4_GI_Reverse: 'https://qascbmpi.scg.com:44332/RESTAdapter/REVGIGRRefDN',
  LOG: 'https://localhost:44353/api/SCG',
  PDF: 'https://localhost:44353/api/PdfCreator/PDF',
  DNGetList: 'https://qascbmpi.scg.com:44332/RESTAdapter/DNGetList',
  R3DNGetList: 'https://qascbmpi.scg.com:44332/RESTAdapter/R3DNGetList',
  DNGetGroup: 'https://qascbmpi.scg.com:44332/RESTAdapter/DNGetGroup',
  ShowLogInDev:true,
  redirectUrl: 'https://scgdpurchasing.nexterdigitals-dev.com',
  clientId:'07681525-3d30-446a-8ae7-8420206ee621',
  tenantId:'5db8bf0e-8592-4ed0-82b2-a6d4d77933d4',

};

export const ADFS = {
  production: false,
  issuer: 'https://accessdev.scg.co.th/adfs/ls/',
  resource: 'https://scgdpurchasing.nexterdigitals-dev.com',
  redirectUri: 'https://scgdpurchasing.nexterdigitals-dev.com/login',
  loginUrl: 'https://accessdev.scg.co.th/adfs/oauth2/authorize',
  tokenEndpoint: 'https://accessdev.scg.co.th/adfs/oauth2/token',
  logoutUrl: 'https://scgdpurchasing.nexterdigitals-dev.com',
  clientId: 'afde483b-544e-43cd-9cbc-c88c16998f0b',
  responseType: 'code',
  scope: '',
  oidc: false,
};


export const authConfig: AuthConfig = {
  issuer: 'https://accessdev.scg.co.th/adfs',
  loginUrl: 'https://accessdev.scg.co.th/adfs/oauth2/authorize/',
  logoutUrl: 'https://accessdev.scg.co.th/adfs/oauth2/logout',
  redirectUri: 'https://scgdpurchasing.nexterdigitals-dev.com/login',
  clientId: 'afde483b-544e-43cd-9cbc-c88c16998f0b',
  scope: 'profile',
  responseType: 'id_token'
}

